import React from 'react'
import './Actions.scss'
import { Link } from 'react-router-dom'
import { classMaker } from '../..'

export default function Actions({ className, css, buttons, visibility, rootPath, options, ...props }) {

  function clickHandler(btn) {
    let funcName = 'on' + btn[0].toUpperCase() + btn.slice(1)
    let func = props[funcName]
    func && func()
  }

  const Icon = ({ btn, index, src, width, height }) => {
    return (
      <img
        className={btn}
        key={index}
        src={src}
        style={{ width: width, height: height }}
        onClick={(e) => {
          e.stopPropagation()
          clickHandler(btn)
        }}
      />
    )
  }

  return (
    <span id='Actions' className={classMaker(className, css, 'Actions')}>
      {
        buttons && Object.keys(buttons).map((btn, index) => {
          let src = rootPath ? rootPath + buttons[btn] : buttons[btn]
          if (!visibility?.[btn] && visibility?.[btn] !== undefined) return null
          if (props[btn + 'Link']) {
            return (
              <Link key={index} to={props[btn + 'Link'].to} state={props[btn + 'Link'].state} target='_blank' >
                <Icon btn={btn} index={index} src={src} width={options?.[btn]?.size} height={options?.[btn]?.size} />
              </Link>
            )
          } else {
            return (
              <Icon key={index} btn={btn} index={index} src={src} width={options?.[btn]?.size} height={options?.[btn]?.size} />
            )
          }
        })
      }
    </span>
  )
}