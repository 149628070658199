import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'
import './Layout.scss'
import { classMaker } from '../..'
import Others from '../Others/Others'

export default function Layout({ className, css, sidebar, header }) {

    return (
        <div id='Layout' className={classMaker(className, css, 'Layout')}>
            <aside>{sidebar}</aside>
            <div>
                <header>{header}</header>
                <main>
                    <Outlet context={{ ...useOutletContext() }} />
                    <Others />
                </main>
            </div>
        </div>
    )
}
