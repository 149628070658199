import { components } from "react-select";
import Layout from "./_common/_components/Layout/Layout";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import Accounts from "./pages/Accounts";
import AddClass from "./pages/AddClass";
import AddFees from "./pages/AddFees";
import AddStudent from "./pages/AddStudent";
import Classes from "./pages/Classes";
import Fees from "./pages/Fees";
import Home from "./pages/Home";
import Login from "./pages/Login";
import PayFees from "./pages/PayFees";
import Salary from "./pages/Salary";
import Staffs from "./pages/Staffs";
import AddStaff from "./pages/AddStaff";
import AddSalary from "./pages/AddSalary";

export default [
    {
        path: "login",
        component: <Login />,
    },
    {
        wrapper_component: <Layout sidebar={<SideBar />} header={<Header />} />,
        items: [
            {
                label: "Accounts",
                path: "accounts",
                icon: '/icons/menu/accounts.svg',
                component: <Accounts />
            },
            {
                label: "Fees",
                path: "fees",
                icon: '/icons/menu/fees.svg',
                component: <Fees />,
                items: [
                    {
                        path: "add_fees",
                        component: <AddFees />,
                        breadcrumb: 'Add Fees'
                    },
                    {
                        path: "pay_fees",
                        component: <PayFees />,
                        breadcrumb: 'Pay Fees'
                    },
                ]
            },
            {
                label: "Salary",
                path: "salary",
                icon: '/icons/menu/salary.svg',
                component: <Salary />, items: [
                    {
                        path: "add_salary",
                        component: <AddSalary />,
                        breadcrumb: 'Add Salary'
                    },
                ]
            },
            {
                label: "Students",
                path: "/",
                icon: '/icons/menu/students.svg',
                component: <Home />,
                items: [
                    {
                        path: "add_student",
                        component: <AddStudent />,
                        breadcrumb: 'Add Student'
                    },
                    {
                        path: "edit_student/:id",
                        component: <AddStudent />,
                        breadcrumb: 'Edit Student'
                    },
                ]
            },
            {
                label: "Staffs",
                path: "staffs",
                icon: '/icons/menu/staffs.svg',
                component: <Staffs />,
                items: [
                    {
                        path: 'add_staff',
                        component: <AddStaff />,
                        breadcrumb: 'Add Staff'
                    },
                    {
                        path: "edit_staff/:id",
                        component: <AddStaff />,
                        breadcrumb: 'Edit Staff'
                    },
                ]
            },
            {
                label: "Classes",
                path: "classes",
                icon: '/icons/menu/classes.svg',
                component: <Classes />,
                items: [
                    {
                        path: "add_class",
                        component: <AddClass />,
                        breadcrumb: 'Add Class'
                    },
                ]
            }
        ]
    }
]