import React, { useCallback, useState } from 'react'
import Page from '../_common/_components/Page/Page'
import Title from '../_common/_components/Title/Title'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import Flex from '../_common/_components/Flex/Flex'
import Input from '../_common/_components/Input/Input'
import useForm from '../_common/_Hooks/useForm'
import Button, { Buttons } from '../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'
import RadioButtons from '../_common/_components/RadioButtons/RadioButtons'
import useFetch from '../_common/_Hooks/useFetch'
import { toast } from 'react-toastify'
import Select from '../_common/_components/Select/Select'

export default function AddFees() {

    const navigate = useNavigate()
    const { formPlug, inputs, validate, setInputs, groupInputs, inputHandler, setErrors } = useForm({ type: 'monthly' })

    const [filteredClasses, setFilteredClasses] = useState([])

    // **********************************************************************************************************
    const { req: addFees } = useFetch({
        endpoint: 'add_fees',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // **********************************************************************************************************
    // const { res: classes_res } = useFetch({
    //     endpoint: 'get_classes',
    //     method: 'get',
    //     type: 'raw',
    //     selfCall: true,
    // })
    // // **********************************************************************************************************
    const { req: getStudentsDetails, res: classes_res } = useFetch({
        endpoint: 'get_students',
        method: 'post',
        type: 'raw',
        selfCall: true,
        toast: true,
        success: (res) => {
            //     let r = res.data.reduce((a, c) => [...a, ...c.students], []).reduce((a, c) => ({ ...a, [c._id + 'student']: c.fees || '' }), {})
            //     setInputs(s => ({ ...s, ...r }))
            // console.log(inputs.type, inputs.type === 'academicFee' ? [res.data[0]] : res.data)
            setFilteredClasses(res.data)
        }
    })
    // **********************************************************************************************************
    const classes = classes_res?.data
    // **********************************************************************************************************

    console.log({ inputs })

    async function saveHandler() {
        if (!await validate()) return
        const classDetails = classes.map(item => ({ class: item._id, fee: inputs[item._id] }))
        if (classDetails.reduce((a, c) => a + Number(c.fee), 0) === 0) {
            toast.error('Please enter fees amount', { position: 'bottom-center' })
            return
        }
        addFees({
            type: inputs.type,
            month: inputs.month?.value || inputs.month,
            classes: classDetails.filter(a => a.fee),
            class: inputs.class._id,
            students: Object.entries(groupInputs({ includes: 'student', hideEmpty: true })).map(i => {
                return {
                    student: i[0],
                    fee: i[1]
                }
            })
        }, (res) => {
            if (res.code === 11000) {
                setErrors(res.errors)
                setTimeout(() => { document.getElementById('error')?.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' }) }, 0);
            } else {
                navigate('/fees')
            }
        })
    }

    // console.log(Object.entries(groupInputs({ includes: 'student' })).map(i => {
    //     return {
    //         student: i[0],
    //         fee: i[1]
    //     }
    // }))

    return (
        <Page className='AddFees'>

            <Page.Header>
                <Title title={'Add Fees'} />
                <Breadcrumb content={[
                    { path: '/fees', name: 'Fees' },
                    { path: '/add_fees', name: 'Add Fees' }
                ]} />
            </Page.Header>

            <Page.Body>

                <RadioButtons
                    label='Type'
                    field='type'
                    inputSocket={formPlug}
                    buttons={[
                        { label: 'Monthly Fee', value: 'monthly' },
                        { label: 'Academic Fee', value: 'academicFee' },
                        // { label: 'Exam Fee', value: 'exam' }
                    ]}
                    onChange={(value, key) => {
                        inputHandler(value, key)
                        getStudentsDetails({}, (res) => {
                            let r = (value === 'academicFee' ? [res.data[0]] : res.data)
                                .reduce((a, c) => [...a, ...c.students], [])
                                .reduce((a, c) => ({ ...a, [c._id + 'student']: (value === 'academicFee' ? c.academic_fees : c.fees) || '' }), {})
                            console.log({ res: (value === 'academicFee' ? [res.data[0]] : res.data).reduce((a, c) => [...a, ...c.students], []) })
                            setInputs(({ type }) => ({ type, ...r, class: res.data[0] }))
                            setFilteredClasses(value === 'academicFee' ? [res.data[0]] : res.data)
                        })
                    }}
                />
                {
                    <>
                        <Flex columns={4} gap={20} mt={20} >
                            {inputs.type === 'academicFee'
                                ? <>
                                    <Select css={1} inputSocket={formPlug} label='Class' field='class' required
                                        content={{ op: classes, lb: (l) => l.class.name, vl: (v) => v.class?._id }}
                                        onChange={(e, key) => {
                                            // inputHandler(e, key)
                                            // inputHandler(null, 'month')
                                            setInputs(s => ({
                                                type: s.type,
                                                [key]: e,
                                                ...classes.filter(item => item.class._id === e.class._id).reduce((a, c) => [...a, ...c.students], []).reduce((a, c) => ({ ...a, [c._id + 'student']: c.academic_fees || '' }), {})
                                            }))
                                            setFilteredClasses([e])
                                        }} />
                                    <Select css={1} inputSocket={formPlug} label='Term' field='month' required
                                        content={{ op: inputs.class?.class?.terms, lb: 'name', vl: 'value' }} />
                                </>
                                : inputs.type === 'monthly' && <>
                                    <Input css={1} inputSocket={formPlug} type='month' label='Month' field='month' required />
                                </>
                            }
                        </Flex>

                        <p className='class_title'>Fees Amount</p>

                        <div className='classes'>
                            {
                                filteredClasses?.map((classItem, i) => {
                                    return (
                                        <div className='students' key={i}>
                                            {inputs.type !== 'academicFee' && <p className='class_name'>{classItem.class.name}</p>}
                                            {
                                                classItem.students.map((student, j) => {
                                                    return (
                                                        <React.Fragment key={j}>
                                                            <Input css={1.1} inputSocket={formPlug} label={student.name} field={student._id + "student"} type='number' placeholder={'000'} />
                                                            <br />
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                }
            </Page.Body>

            <Buttons mt={60} gap={30}>
                <Button css={2.1} content='Cancel' onClick={() => navigate(-1)} />
                <Button css={2} content='Save' onClick={saveHandler} />
            </Buttons>

        </Page>
    )
}
