const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function monthNameGetter(string) {
    if (!string) return string
    const split = string?.split('-')
    const monthName = months[Number(split[1] || 0) - 1]
    const year = split[0]
    return year + '-' + monthName
}

export function monthNameSetter(string) {
    if (!string) return string
    const split = string?.split('-')
    return split[2] + '-' + months[Number(split[1] || 0) - 1] + '-' + split[0]
}