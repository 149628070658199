import React, { useEffect } from 'react'
import './Grid.scss'
import { classMaker } from '../..'

export default function Grid({ className, css, columns, children, onEmpty, gap, rowGap, colGap, width, id, mb, mt, mr, ml }) {

  let colno = 1

  useEffect(() => {
    if (onEmpty) {
      let c = Array.isArray(children) ? children : [children]
      let hasChildren = c.some(s => s)
      !hasChildren && onEmpty()
    }
  }, [])

  return (
    <div id='Grid'
      className={classMaker(className, css, 'Grid')}
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gap: gap,
        rowGap: rowGap,
        columnGap: colGap,
        width: width,
        marginBottom: mb,
        marginTop: mt,
        marginRight: mr,
        marginLeft: ml
      }}>
      {
        (!Array.isArray(children) ? [children] : children).map((item, i) => {

          let span = String(item?.props?.span)

          if (span === 'start') colno = 1;

          let style
          if (span?.includes('fill')) style = { gridColumn: `${colno} / -1` }
          if (span?.includes('start')) style = { ...style, gridColumnStart: 1 }
          if (span?.match(/\d+/g)?.[0] > 0) style = { gridColumn: (span?.includes('start') ? '1 / ' : '') + 'span ' + span?.match(/\d+/g)?.[0] }

          // id === 'test' && console.log(col, style)

          let comp = item ? <span style={style} key={i}>{item}</span> : null

          colno % columns === 0 || span === 'fill' || span > 0 && colno + span > columns
            ? colno = 1
            : span > 0
              ? colno += span
              : colno++

          return comp
        })
      }
    </div >
  )
}