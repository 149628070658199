import React from 'react'
import Page from '../_common/_components/Page/Page'
import Title from '../_common/_components/Title/Title'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import Button from '../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'
import useFetch from '../_common/_Hooks/useFetch'
import Table from '../_common/_components/Table/Table'
import useModal from '../_common/_Hooks/useModal'
import { AddClass } from '../common/Modals'

export default function Classes() {

    const navigate = useNavigate()
    const m_AddClass = useModal(<AddClass />)

    // **********************************************************************************************************
    const { res: details_res } = useFetch({
        endpoint: 'get_classes',
        method: 'get',
        type: 'raw',
        selfCall: true,
    })
    // **********************************************************************************************************
    const { req: addClass } = useFetch({
        endpoint: 'add_class',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // **********************************************************************************************************
    const classes = details_res?.data
    // **********************************************************************************************************

    async function addClassHandler() {
        const { close, cancel, m_inputs } = await m_AddClass.show()
        if (close || cancel) return
        addClass(m_inputs)
    }

    return (
        <Page>

            <Page.Header>
                <Title title={'Classes'} />
                <Breadcrumb content={[
                    { path: '/', name: 'Home' },
                    { path: '/', name: 'Classes' }
                ]} />
                {/* <Button css={1} content='Add Class' icon={'/icons/plus.svg'} onClick={addClassHandler} /> */}
            </Page.Header>

            <Page.Body>
                <Table
                    css={1}
                    titles={[
                        { title: 'Sl No', key: 'sl_no', d_align: 'center', width: 40 },
                        { title: 'Name', key: 'name', h_align: 'left' },
                        { title: 'Batch', key: 'batch', h_align: 'left' },
                        { title: 'Class Teacher', key: 'class_teacher', h_align: 'left' },
                        { title: 'Class Leader', key: 'class_leader', h_align: 'left' },
                    ]}
                    content={classes?.map((item, i) => {
                        return (
                            {
                                sl_no: i + 1,
                                batch: item.batch,
                                name: item.name,
                                class_teacher: item.class_teacher,
                                class_leader: item.class_leader,
                                batch: item.batch,
                            }
                        )
                    })}
                />
            </Page.Body>

        </Page>
    )
}
