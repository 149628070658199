import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { CommonContext, storeUpdate } from '..'
import { useOutletContext } from 'react-router-dom'
import config from '../../common/config'

export default function useTranslate() {

    const { translationData } = useContext(CommonContext)
    const { addTranslation } = useOutletContext() || {}

    const inAction = useSelector(s => s.translation_inAction) || []

    const { language, setLanguage } = useContext(CommonContext)

    function keyGetter() {
        return language.toLowerCase() === 'ar' ? '_ar' : '_en'
    }

    function addWordHandler(value) {
        let obj = {
            title: value.toLowerCase().trim(),
            arabic_text: value.trim() + '_a',
            english_text: value.trim(),
            is_verified: false,
        }
        addTranslation && addTranslation(obj)
    }

    function t(text) {
        if (!text) return text
        if (translationData?.en?.[text]) {
            return language === 'en' ? text : translationData?.en[text]
        } else {
            if (config.useTranslate_autoTranslate.autoTranslate) {
                if (!inAction?.includes(text.toLowerCase()) && !config.useTranslate_autoTranslate.except.includes(text.toLowerCase().trim())) {
                    text.toLowerCase() && storeUpdate(s => ({ translation_inAction: [...(s.translation_inAction || []), text.toLowerCase()] }))
                    addWordHandler(text)
                }
            }
            return text
        }
    }

    return { t, _lang: keyGetter(), lang: language, setLanguage }
}