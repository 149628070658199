import React from 'react'
import './Header.scss'

export default function Header() {
    return (
        <div id='Header'>

        </div>
    )
}
