import React from 'react'
import Page from '../_common/_components/Page/Page'
import Title from '../_common/_components/Title/Title'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import Button from '../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'
import useModal from '../_common/_Hooks/useModal'
import { SalaryEntry } from '../common/Modals'
import useFetch from '../_common/_Hooks/useFetch'
import Table from '../_common/_components/Table/Table'
import { monthNameGetter, monthNameSetter } from '../utils'

export default function Salary() {
    const navigate = useNavigate()
    const m_paysalary = useModal(<SalaryEntry />)

    // **********************************************************************************************************
    const { res: staffs_res } = useFetch({
        endpoint: 'get_staffs',
        method: 'get',
        type: 'raw',
        selfCall: true,
    })
    // **********************************************************************************************************
    const { req: getSalaries, res: salaries_res } = useFetch({
        endpoint: 'get_salaries',
        method: 'get',
        type: 'raw',
        selfCall: true,
    })
    // **********************************************************************************************************
    const { req: paySalary } = useFetch({
        endpoint: 'pay_salary',
        method: 'post',
        type: 'raw',
        toast: true,
    })
    // **********************************************************************************************************
    const staffs = staffs_res?.data
    const salaries = salaries_res?.data
    // **********************************************************************************************************

    async function salaryPaymentHandler() {
        const { close, cancel, m_inputs } = await m_paysalary.show({ staffs })
        if (close || cancel) return
        paySalary(m_inputs, () => {
            getSalaries()
        })
    }
    return (
        <Page className='Salary'>

            <Page.Header>
                <Title title={'Salary'} />
                <Breadcrumb content={[
                    { path: '/', name: 'Home' },
                    { path: '/', name: 'Salary' }
                ]} />
                <Button css={1} content='Pay Salary' icon={'/icons/plus.svg'} onClick={salaryPaymentHandler} />
                <Button css={1} content='Add Salary' icon={'/icons/plus.svg'} onClick={() => navigate('add_salary')} />
            </Page.Header>

            <Page.Body>

                {salaries?.map(salary => {
                    return (
                        <>
                            <p className='salary_month'>{monthNameGetter(salary.month)}</p>
                            <Table
                                className='salaries_table'
                                css={1.1}
                                titles={[
                                    { title: 'Sl NO', key: 'sl_no', h_align: 'left', width: 80 },
                                    { title: 'Staff', key: 'staff', h_align: 'left', width: 200 },
                                    { title: 'Salary', key: 'salary', h_align: 'left' },
                                    { title: 'Payments', key: 'payments', h_align: 'left', width: 300 },
                                    { title: 'Total Paid', key: 'total_paid', h_align: 'left', width: 200 },
                                    { title: 'Balance', key: 'balance', h_align: 'left' },
                                ]}
                                content={salary.staffs?.map((staff, i) => {

                                    return (
                                        {
                                            className: staff.key,
                                            sl_no: i + 1,
                                            staff: staff.staff.name,
                                            salary: staff.salary,
                                            payments: staff.payments?.map(pay => <p>{pay.amount + ` (${monthNameSetter(pay.date)}) (${pay.vchr_no})`}</p>),
                                            total_paid: staff.total_paid,
                                            balance: staff.balance,
                                        }
                                    )
                                })}
                                tfoot={
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><p>{salary.total_salary}</p></td>
                                        <td></td>
                                        <td><p>{salary.total_paid}</p></td>
                                        <td><p>{salary.total_balance}</p></td>
                                    </tr>
                                }
                            />
                        </>
                    )
                })}

            </Page.Body>
        </Page>
    )
}
