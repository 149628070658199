import React from 'react'
import Page from '../_common/_components/Page/Page'
import Title from '../_common/_components/Title/Title'
import Flex from '../_common/_components/Flex/Flex'
import Input from '../_common/_components/Input/Input'
import Button, { Buttons } from '../_common/_components/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import useFetch from '../_common/_Hooks/useFetch'
import useForm from '../_common/_Hooks/useForm'
import Select from '../_common/_components/Select/Select'

export default function AddStudent() {

    const { id } = useParams()
    const navigate = useNavigate()

    const { formPlug, inputs, validate, setInputs, setErrors } = useForm()

    // **********************************************************************************************************
    const { req: addStudent } = useFetch({
        endpoint: 'add_student',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // **********************************************************************************************************
    const { res: details_res } = useFetch({
        endpoint: 'get_addStudentDetails',
        method: 'get',
        type: 'raw',
        selfCall: true
    })
    // **********************************************************************************************************
    const { req: update } = useFetch({
        endpoint: 'student',
        method: 'put',
        type: 'raw',
        toast: true
    })
    // **********************************************************************************************************
    useFetch({
        endpoint: 'student',
        method: 'get',
        type: 'params',
        selfCall: id,
        success: (res) => {
            setInputs(res.data)
        }
    })
    // **********************************************************************************************************
    const details = details_res?.data
    // **********************************************************************************************************

    async function saveHandler() {
        if (!await validate()) return
        id
            ? update({ ...inputs, class: inputs.class._id }, (res) => {
                if (res.code === 11000) {
                    setErrors(res.errors)
                    setTimeout(() => { document.getElementById('error')?.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' }) }, 0);
                } else {
                    navigate('/')
                }
            })
            : addStudent({ ...inputs, class: inputs.class._id }, (res) => {
                if (res.code === 11000) {
                    setErrors(res.errors)
                    setTimeout(() => { document.getElementById('error')?.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' }) }, 0);
                } else {
                    navigate('/')
                }
            })
    }

    const bloodGroupes = [
        { label: 'A +ve', value: 'a-positive' },
        { label: 'A -ve', value: 'a-negative' },
        { label: 'B +ve', value: 'b-positive' },
        { label: 'B -ve', value: 'b-negative' },
        { label: 'O +ve', value: 'o-positive' },
        { label: 'O -ve', value: 'o-negative' },
        { label: 'AB +ve', value: 'ab-positive' },
        { label: 'AB -ve', value: 'ab-negative' },
    ]

    console.log({ inputs })
    return (
        <Page>

            <Page.Header>
                <Title title={id ? 'Edit Student' : 'Add Student'} />
                <Breadcrumb content={[
                    { path: '/', name: 'Students' },
                    { path: '/add_student', name: id ? 'Edit Student' : 'Add Student' }
                ]} />
            </Page.Header>

            <Page.Body>

                <Title css={1} mt={10} title='Personal' />

                <Flex columns={4} gap={20}>
                    <Input css={1} inputSocket={formPlug} label='Name' field='name' required />
                    <Input css={1} inputSocket={formPlug} label='CIC Number' field='cic_number' required type='number' />
                    <Input css={1} inputSocket={formPlug} label='Date of Birth' field='dob' type='date' />
                    <Input css={1} inputSocket={formPlug} label='TC Number' field='tc' type='number' />
                    <Input css={1} inputSocket={formPlug} label='Admission Number' field='admission_number' type='number' />
                    <Input css={1} inputSocket={formPlug} label='Adhar Number' field='adhar_number' type='number' />
                    <Select css={1} inputSocket={formPlug} label='Blood Group' field='blood_group'
                        content={{ op: bloodGroupes, lb: 'label', vl: 'value' }} />
                    <Select css={1} inputSocket={formPlug} label='Class' field='class' required
                        content={{ op: details?.classes, lb: 'name', vl: '_id' }} />
                    <Input css={1} inputSocket={formPlug} label='Fees' field='fees' type='number' required />
                    <Input css={1} inputSocket={formPlug} label='Academic Fees' field='academic_fees' type='number' required />
                </Flex>

                <Title css={1} title='Parents' />

                <Flex columns={4} gap={20}>
                    <Input css={1} inputSocket={formPlug} label="Father's Name" field='father_name' />
                    <Input css={1} inputSocket={formPlug} label="Mother's Occupation" field='father_job' />
                </Flex>

                <Flex columns={4} gap={20} mt={20}>
                    <Input css={1} inputSocket={formPlug} label="Mother's Name" field='mother_name' />
                    <Input css={1} inputSocket={formPlug} label="Mother's Occupation" field='mother_job' />
                </Flex>

                <Title css={1} title='Address' />

                <Flex columns={4} gap={20}>
                    <Input css={1} inputSocket={formPlug} label='House Name' field='house' />
                    <Input css={1} inputSocket={formPlug} label='Place' field='place' />
                    <Input css={1} inputSocket={formPlug} label='Panchayath' field='panchayath' />
                    <Input css={1} inputSocket={formPlug} label='Post Office' field='post' />
                    <Input css={1} inputSocket={formPlug} label='District' field='district' />
                    <Input css={1} inputSocket={formPlug} label='State' field='state' />
                    <Input css={1} inputSocket={formPlug} label='PIN Number' field='pin' type='number' />
                </Flex>

                <Title css={1} title='Contacts' />

                <Flex columns={4} gap={20}>
                    <Input css={1} inputSocket={formPlug} label='Phone' field='phone' required type='number' />
                    <Input css={1} inputSocket={formPlug} label='WhatsApp' field='whatsapp' required type='number' />
                </Flex>

                <Buttons mt={60} gap={30}>
                    <Button css={2.1} content='Cancel' onClick={() => navigate(-1)} />
                    <Button css={2} content='Save' onClick={saveHandler} />
                </Buttons>

            </Page.Body>

        </Page >
    )
}
