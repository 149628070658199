import React from 'react'
import Page from '../_common/_components/Page/Page'
import Title from '../_common/_components/Title/Title'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import Button from '../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'
import useFetch from '../_common/_Hooks/useFetch'
import Table from '../_common/_components/Table/Table'
import { monthNameSetter } from '../utils'
import useModal from '../_common/_Hooks/useModal'
import { AccountEntry } from '../common/Modals'
import Actions from '../_common/_components/Actions/Actions'

export default function Accounts() {

    const navigate = useNavigate()
    const modal_addEntry = useModal(<AccountEntry />)

    // **********************************************************************************************************
    const { req: getDetails, res: details_res } = useFetch({
        endpoint: 'accounts',
        method: 'get',
        type: 'raw',
        selfCall: true,
    })
    // **********************************************************************************************************
    const { req: addIncome } = useFetch({
        endpoint: 'add_income',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // **********************************************************************************************************
    const { req: addExpense } = useFetch({
        endpoint: 'add_expense',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // **********************************************************************************************************
    const { req: addCategory } = useFetch({
        endpoint: 'add_category',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // **********************************************************************************************************
    const { req: getCategories, res: categories_res } = useFetch({
        endpoint: 'getCategories',
        method: 'get',
        selfCall: true
    })
    // **********************************************************************************************************
    const { req: getIncomeDetail } = useFetch({
        endpoint: 'getIncomeDetail',
        method: 'get',
        type: 'params'
    })
    // **********************************************************************************************************
    const { req: getExpenseDetail } = useFetch({
        endpoint: 'getExpenseDetail',
        method: 'get',
        type: 'params'
    })
    // **********************************************************************************************************
    const details = details_res?.data
    const categories = categories_res?.data
    // **********************************************************************************************************

    async function incomeHandler(data) {
        const { close, cancel, submitted, categoryForm, m_inputs } = await modal_addEntry.show({
            type: 'income',
            submitApi: addIncome,
            addCategory,
            categories,
            getCategories,
            data
        })
        if (close || cancel) return
        if (submitted) {
            getDetails()
        }
        // if (categoryForm) {
        //     addCategory({ ...m_inputs, type: 'income', _id: data._id }, () => {
        //         getCategories()
        //     })
        // }
        // else {
        //     addIncome(m_inputs, () => {
        //         getDetails()
        //     })
        // }
    }

    async function expenseHandler(data) {
        const { close, cancel, submitted, categoryForm, m_inputs } = await modal_addEntry.show({
            type: 'expense',
            submitApi: addExpense,
            addCategory,
            categories,
            getCategories,
            data
        })
        if (close || cancel) return
        if (submitted) {
            getDetails()
        }
        // if (categoryForm) {
        //     addCategory({ ...m_inputs, type: 'expense', _id: data._id }, () => {
        //         getCategories()
        //     })
        // }
        // else {
        //     addExpense(m_inputs, () => {
        //         getDetails()
        //     })
        // }
    }

    async function editHandler(item) {
        if (item.key === 'income') {
            getIncomeDetail({ id: item._id }, (res) => {
                incomeHandler(res.data)
            })
        }
        if (item.key === 'expense') {
            getExpenseDetail({ id: item._id }, (res) => {
                expenseHandler(res.data)
            })
        }
    }

    return (
        <Page>

            <Page.Header>
                <Title title={'Accounts'} />
                <Breadcrumb content={[
                    { path: '/', name: 'Home' },
                    { path: '/', name: 'Accounts' }
                ]} />
                <p className='val_income'><b>Total Income</b> : {details?.totalIncome}</p>
                <p className='val_expense'><b>Total Expense</b> : {details?.totalExpense}</p>
                <p className='val_balance'><b>Balance</b> : {details?.balance}</p>
                <Button css={1} content='Income' icon={'/icons/plus.svg'} onClick={incomeHandler} />
                <Button css={1} content='Expense' icon={'/icons/plus.svg'} onClick={expenseHandler} />

            </Page.Header>

            <Page.Body>
                <Table
                    css={1.1}
                    titles={[
                        { title: 'Sl NO', key: 'sl_no', h_align: 'left', width: 20 },
                        { title: 'Date', key: 'date', h_align: 'left', width: 150 },
                        { title: 'Category', key: 'category', h_align: 'left', width: 150 },
                        { title: 'Particular', key: 'type', h_align: 'left', width: 250 },
                        { title: 'Paid By / Paid To', key: 'paidBy', h_align: 'left' },
                        { title: 'Receipt No', key: 'receipt_no', h_align: 'left' },
                        { title: 'Amount', key: 'amount', d_align: 'right', width: 40 },
                        { title: '', key: 'action', d_align: 'right', width: 40 },
                    ]}
                    content={details?.entries?.map((item, i) => {
                        let classCode = item.paidBy?.class?.code ? `(${item.paidBy?.class?.code})` : ''
                        return (
                            {
                                className: item.key,
                                sl_no: i + 1,
                                date: monthNameSetter(item.date),
                                receipt_no: item.receipt_no || item.vchr_no,
                                type: item.type || item.remarks,
                                category: item.category || item.particular.categoryname,
                                paidBy: (item.paidBy?.name || item?.paid_to || item.staff?.name || item.creditedBy) + " " + classCode,
                                amount: item.amount,
                                action: <Actions
                                    buttons={{ edit: '/icons/edit.svg' }}
                                    visibility={{ edit: item.particular !== undefined }}
                                    onEdit={() => editHandler(item)}
                                />
                            }
                        )
                    })}
                />
            </Page.Body>

        </Page>
    )
}
