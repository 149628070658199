import React from 'react'
import Page from '../_common/_components/Page/Page'
import Title from '../_common/_components/Title/Title'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import Button from '../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'
import useFetch from '../_common/_Hooks/useFetch'
import Flex from '../_common/_components/Flex/Flex'
import Card from '../components/Card/Card'

export default function Fees() {

    const navigate = useNavigate()


    // **********************************************************************************************************
    const { res: details_res } = useFetch({
        endpoint: 'get_fees',
        method: 'get',
        type: 'raw',
        selfCall: true,
    })
    // **********************************************************************************************************
    const details = details_res?.data
    // **********************************************************************************************************

    return (
        <Page>

            <Page.Header>
                <Title title={'Fees'} />
                <Breadcrumb content={[
                    { path: '/', name: 'Home' },
                    { path: '/', name: 'Fees' }
                ]} />
                <Button css={1} content='Pay Fees' icon={'/icons/plus.svg'} onClick={() => navigate('pay_fees')} />
                <Button css={1} content='Add Fees' icon={'/icons/plus.svg'} onClick={() => navigate('add_fees')} />
            </Page.Header>

            <Page.Body>

                <Title css={1} mt={10} title='Active Monthly Fees Collection' />

                {details?.monthlyFees.length > 0
                    ? <Flex columns={3} gap={15} _minWidth={350}>
                        {
                            details?.monthlyFees.map(x => {
                                return (
                                    <Card data={x} />
                                )
                            })
                        }
                    </Flex>
                    : <div className='fees_unavailable'>
                        <img src="" alt="" />
                        <p>No fees were created</p>
                    </div>
                }

                <Title css={1} mt={60} title='Academic Fees Collection' />

                {
                    details?.otherFees.length > 0
                        ? <Flex columns={3} gap={15} _minWidth={350}>
                            {
                                details?.otherFees.map(x => {
                                    return (
                                        <Card data={x} />
                                    )
                                })
                            }
                        </Flex>
                        : null
                }

            </Page.Body>

        </Page>
    )
}
