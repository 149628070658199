import React from 'react'
import './StudentCard.scss'
import { useNavigate } from 'react-router-dom'

export default function StudentCard({ data }) {
    const navigate = useNavigate()
    return (
        <div id='StudentCard'>
            <img className='student_pic' width={80} height={80} src="/images/student.jpg" alt="" />
            <img className='edit_icon' width={30} src="/icons/edit.svg" alt="" onClick={() => navigate('/edit_student/' + data._id)} />
            <p className='name'>{data.name}</p>
            <p className='cicno'>{data.cic_number}</p>
        </div>
    )
}
