import React from 'react'
import './SideBar.scss'
import NavBar from '../_common/_components/NavBar/NavBar'
import routes from '../routes'

export default function SideBar() {
    return (
        <div id='SideBar'>
            <div className="logo">
                <img src="/images/logo_pic.jpg" alt="" />
                <p>BKR WAFIYYA</p>
            </div>
            <NavBar menuList={routes} />
        </div>
    )
}
