import React from 'react'
import Page from '../_common/_components/Page/Page'
import Title from '../_common/_components/Title/Title'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import Flex from '../_common/_components/Flex/Flex'
import Input from '../_common/_components/Input/Input'
import useForm from '../_common/_Hooks/useForm'
import useFetch from '../_common/_Hooks/useFetch'
import Button, { Buttons } from '../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'

export default function AddSalary() {

    const navigate = useNavigate()

    const { formPlug, inputs, validate, setInputs, groupInputs } = useForm()

    // **********************************************************************************************************
    const { res: staffs_res } = useFetch({
        endpoint: 'get_staffs',
        method: 'get',
        type: 'raw',
        selfCall: true,
        success: (res) => {
            let r = res.data.reduce((a, c) => ({ ...a, [c._id + 'staff']: c.salary || '' }), {})
            setInputs(s => ({ ...s, ...r }))
        }
    })
    // **********************************************************************************************************
    const { req: addSalary } = useFetch({
        endpoint: 'add_salary',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // **********************************************************************************************************
    const staffs = staffs_res?.data
    // **********************************************************************************************************

    async function saveHandler() {
        if (!await validate()) return
        addSalary({
            type: inputs.type,
            month: inputs.month,
            // classes: classDetails.filter(a => a.fee),
            staffs: Object.entries(groupInputs({ includes: 'staff', hideEmpty: true })).map(i => {
                return {
                    staff: i[0],
                    salary: i[1]
                }
            })
        }, () => {
            navigate('/salary')
        })
    }

    console.log({ inputs })

    return (
        <Page className='AddSalary'>

            <Page.Header>
                <Title title={'Add Salary'} />
                <Breadcrumb content={[
                    { path: '/salary', name: 'Salary' },
                    { path: '/add_salary', name: 'Add Salary' }
                ]} />
            </Page.Header>

            <Page.Body>
                <Flex columns={4} gap={20} mt={20} >
                    <Input css={1} inputSocket={formPlug} type='month' label='Month' field='month' required />
                </Flex>

                <p className='salary_title'>Salary Amount</p>

                <div className="staffs">
                    {
                        staffs?.map(staff => {
                            return (
                                <>
                                    <Input css={1.1} inputSocket={formPlug} label={staff.name} field={staff._id + "staff"} type='number' placeholder={'000'} />
                                    <br />
                                </>
                            )
                        })
                    }
                </div>

                <Buttons mt={60} gap={30}>
                    <Button css={2.1} content='Cancel' onClick={() => navigate(-1)} />
                    <Button css={2} content='Save' onClick={saveHandler} />
                </Buttons>

            </Page.Body>
        </Page>

    )
}
