import React, { useState, useEffect, useRef } from 'react'
import './Select.scss'
import ReactSelect from 'react-select'
import { MultiSelect } from "react-multi-select-component";
import useTranslate from '../../_Hooks/useTranslate'
import DropDownicon from "./dropdown.svg"
import { classMaker } from '../..';
import __config from '../../../common/config';

export default function Select({ className, css, placeholder, label, value, inputSocket, field, onChange, isMulti, Multi2, style, width, error, showCloseBtn, required, showToggleBtn, disabled, classNamePrefix, condition, clearOnHide, hideStar, content = { op: '', lb: '', vl: '' }, passValue, mb, mt, mr, ml, height, labelIcon, onLabelIconClick }) {

  let connector = inputSocket?.connector
  error = error || inputSocket?.errors
  value = value || inputSocket?.inputs
  onChange = onChange || inputSocket?.inputHandler

  const { t } = useTranslate()
  const selectRef = useRef()

  const initialValue = Multi2 ? [] : ''

  const [list, setList] = useState([])
  const [inValue, setInValue] = useState(initialValue)
  const [toggleBtn, setToggelBtn] = useState(false)
  const [inError, setInError] = useState('')

  useEffect(() => {
    setInValue(() => {
      let res = initialValue
      if (typeof value === 'object' && value?.[field] !== undefined && typeof value?.[field] !== 'string') res = value?.[field]
      else if (typeof value === 'object' && value?.[field] === undefined && !Array.isArray(value) && !(content.op !== undefined && content.op !== '' && content.op?.find(item => setter('value', item) === setter('value', value)))) res = initialValue
      else if (typeof value === 'string') res = content.op?.find(item => item[content.vl] === value)
      else if (typeof value === 'object' && typeof value?.[field] === 'string') res = content.op?.find(item => item[content.vl] === value?.[field])
      else if (value !== undefined && typeof value !== 'string') res = value
      return res
    })
    if (!field) setInError('Please add field')
    content.op && Array.isArray(content.op) && setList(content.op)
    return () => {
      setList([])
      setInValue(Multi2 ? [] : '')
    }
  }, [content.op, value])

  // useEffect(() => {
  //   let a = selectRef.current
  //   a.addEventListener('click', () => {
  //     setTimeout(() => {
  //       let b = a.querySelector('div.search')
  //       let d = a.querySelector('.dropdown-heading-value')
  //       let c = b
  //       // d.append(b)
  //       // b.focus()
  //       // console.log(b)
  //       // console.log(d)
  //     }, 0);
  //   })
  // }, [])

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field }
      if (required) obj.required = required
      connector && connector(obj)
      if (Object.keys(obj).length > 1 && !connector) setInError("Please connect 'formPlug' to 'inputSocket'")
    }
    return () => { connector && connector({ key: field, clearValidation: true }) }
  }, [...(required && typeof required === 'object' ? Object.values(required) : [required]), condition])

  useEffect(() => {
    return () => {
      condition && clearOnHide && connector && connector({ key: field, clearValidation: true, clearValue: true })
    }
  }, [condition])

  function changeHandler(e) {
    setInValue(e)
    onChange && onChange(passValue ? e[content?.vl] : e, field)
  }

  function setter(type, item) {
    let flag = { label: content.lb, value: content.vl }
    if (flag[type] instanceof Function) return flag[type](item)
    if (typeof flag[type] === 'string') return item?.[flag[type]]
  }

  if (!condition && condition !== undefined) return null

  return (
    <span id='Select' className={classMaker(className, css, 'Select') + (showCloseBtn && inValue ? ' close_button_appear' : '')}
      onClick={(e) => e.stopPropagation()}
      style={{
        ...style,
        width: width,
        marginBottom: mb,
        marginTop: mt,
        marginRight: mr,
        marginLeft: ml,
        '--slc_height': height ? (height + 'px') : null
      }}>
      {label && <p className='title'>
        {t(label)}
        {(required?.condition !== undefined ? required?.condition : required) && !hideStar && <em>*</em>}
        {showToggleBtn && <img className={'toggle_arrow' + (toggleBtn ? ' expanded' : ' collapsed')}
          src={DropDownicon} alt=""
          onClick={() => setToggelBtn(s => !s)}
        />}
        {labelIcon && <img className='labelIcon' src={labelIcon} width={30} onClick={onLabelIconClick} />}
      </p>}
      {
        !toggleBtn &&
        <span className='select_span' ref={selectRef}>
          {Multi2
            ? <MultiSelect
              className='select_multi_select'
              options={list.map(item => {
                return {
                  ...item,
                  label: setter('label', item),
                  value: setter('value', item)
                }
              })}
              value={(Array.isArray(inValue) ? inValue : [inValue]).map(item => {
                return {
                  ...item,
                  label: setter('label', item),
                  value: setter('value', item)
                }
              })}
              hasSelectAll={false}
              overrideStrings={{
                selectSomeItems: placeholder || "Select",
              }}
              labelledBy={"name"}
              disabled={list.length === 0 || disabled}
              onChange={changeHandler}
            />
            : <ReactSelect
              className='select_react_select'
              isMulti={isMulti}
              options={list}
              value={inValue}
              placeholder={t(placeholder || __config.Select_placeholder)}
              isDisabled={list.length === 0 || disabled}
              getOptionLabel={(o) => setter('label', o)}
              getOptionValue={(o) => setter('value', o)}
              onChange={changeHandler}
              classNamePrefix={classNamePrefix}
            />
          }
          {showCloseBtn && (Multi2 ? inValue.length > 0 : inValue) && <span className='close_btn' onClick={() => { setInValue(''); onChange && onChange('', field) }}>✖</span>}
        </span>
      }
      {(inError || (typeof error === 'object' ? error?.[field] : error)) && <small id='error' className='error'>{inError || (typeof error === 'object' ? t(error?.[field] || '') : t(error))}</small>}
    </span >
  )
}
Select.displayName = 'Select'



{/* 

<Select
  label=''
  className=''
  placeholder='select...'
  options={[
    { a: 1 },
    { a: 2 },
    { a: 3 },
  ]}
  value={{}}
  labelSetter={(e) => e.a}
  valueSetter={(e) => e.a}
  onChange={(e) => { }}
  error={'error message'}
  isMulti
  showCloseBtn
  showToggleBtn
/>

*/}