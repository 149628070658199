import React from 'react'
import Page from '../_common/_components/Page/Page'
import Title from '../_common/_components/Title/Title'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import Flex from '../_common/_components/Flex/Flex'
import Input from '../_common/_components/Input/Input'
import Select from '../_common/_components/Select/Select'
import Table from '../_common/_components/Table/Table'
import Button, { Buttons } from '../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'
import useFetch from '../_common/_Hooks/useFetch'
import RadioButtons from '../_common/_components/RadioButtons/RadioButtons'
import useForm from '../_common/_Hooks/useForm'
import { monthNameGetter } from '../utils'
import Grid from '../_common/_components/Grid/Grid'
import LabelValue from '../_common/_components/LabelValue/LabelValue'
import { toast } from 'react-toastify'

export default function PayFees() {

    const navigate = useNavigate()
    const { formPlug, inputHandler, inputs, validate, groupInputs, setInputs } = useForm({ type: 'monthly' })

    // **********************************************************************************************************
    const { res: details_res } = useFetch({
        endpoint: 'get_classes',
        method: 'get',
        type: 'raw',
        selfCall: true,
    })
    // **********************************************************************************************************
    const { req: getStudents, res: students_res } = useFetch({
        endpoint: 'get_students',
        method: 'post',
        type: 'raw',
        selfCall: true,
    })
    // **********************************************************************************************************
    const { req: payFees } = useFetch({
        endpoint: 'pay_fees',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // **********************************************************************************************************
    const { req: getPreviousPayments, res: previousPayments_res } = useFetch({
        endpoint: '/previous_payments',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // **********************************************************************************************************
    const classes = details_res?.data
    const students = students_res?.data?.reduce((a, c) => [...a, ...c.students], [])
    const previousPayments = previousPayments_res?.data?.previous_payments
    let upcomingPayments = previousPayments_res?.data?.upcoming_payments
    // **********************************************************************************************************
    const totalPay = Object.values(groupInputs({ includes: 'payments', hideEmpty: true })).reduce((a, c) => !isNaN(c) ? a + Number(c) : a, 0)
    // **********************************************************************************************************

    function classChangeHandler(value, field) {
        getStudents({ class: value })
        inputHandler(value, field)
        setInputs(s => ({ type: s.type, clas: value }))
    }

    function studentDataGetter(value, field) {
        getPreviousPayments({ student: value }, (res) => {
            let result = res.data.upcoming_payments.reduce((a, payment) => ({ ...a, [payment._id + 'payments']: payment.balance }), {})
            setInputs(s => ({ ...result, type: s.type, student: value, clas: res.data.class }))
        })
        inputHandler(value, field)
    }

    function amountChangeHandler(value, field) {
        inputHandler(value, field)
    }


    // console.log(groupInputs({ includes: 'payments', hideEmpty: true }))
    async function saveHandler() {
        if (!await validate()) return
        if (totalPay === 0) {
            toast.error('Please enter amount', { position: "bottom-center" })
            return
        }
        const { __id, ...paysGroup } = groupInputs({ includes: 'payments', hideEmpty: true, hideValues: ['0'] })
        payFees({
            amount: totalPay,
            clas: inputs.clas,
            date: inputs.date,
            receipt_no: inputs.receipt_no,
            student: inputs.student,
            type: inputs.type,
            fees: paysGroup
        }, () => {
            navigate('/fees')
        })
    }


    // console.log({ upcomingPayments })
    // console.log({ inputs })
    console.log({ totalPay })


    return (
        <Page>

            <Page.Header>
                <Title title={'Fees Payment'} />
                <Breadcrumb />
            </Page.Header>

            <Page.Body>

                <Title css={1} mt={10} title='Payment Details' />

                <RadioButtons label='Type' field='type' inputSocket={formPlug} buttons={[
                    { label: 'Monthly Fee', value: 'monthly' },
                    // { label: 'Admission Fee', value: 'admission' },
                    // { label: 'Exam Fee', value: 'exam' }
                ]} />

                <Flex columns={4} gap={20} mt={20}>
                    <Select css={1} label='Class' field='clas' content={{ op: classes, lb: 'name', vl: '_id' }} onChange={classChangeHandler} passValue inputSocket={formPlug} required />
                    <Select css={1} label='Student' field='student' content={{ op: students, lb: 'name', vl: '_id' }} onChange={studentDataGetter} passValue inputSocket={formPlug} required />
                    <Input css={1} label='Date' field='date' type='date' inputSocket={formPlug} required />
                    <Input css={1} label='Receipt No.' field='receipt_no' type='number' inputSocket={formPlug} required />
                </Flex>

                <Flex gap={20} gc={30} mt={50} width={'45%'}>
                    {inputs.student &&
                        upcomingPayments?.map(payment => {
                            return (
                                <Input css={2} label={monthNameGetter(payment.month) + ' - ' + payment.type + ' :'} field={payment._id + 'payments'} inputSocket={formPlug} onChange={amountChangeHandler} placeholder='enter amount here' />
                            )
                        })
                    }
                </Flex>

                {/* {console.log()} */}

                {!!totalPay && <LabelValue css={2} label={'Total Amount'} value={totalPay} />}

                <Buttons mt={40} mb={40} gap={30}>
                    <Button css={2.1} content='Cancel' onClick={() => navigate(-1)} />
                    <Button css={2} content='Save' onClick={saveHandler} />
                </Buttons>

                <Title css={1} title='Previous Payments' />

                <Table css={1}
                    titles={[
                        { title: 'Sl No', key: 'sl_no', d_align: 'center' },
                        { title: 'Date', key: 'date', h_align: 'left' },
                        { title: 'Details', key: 'details', d_align: 'left' },
                        { title: 'Receipt No', key: 'receipt_no', d_align: 'center' },
                        // { title: 'Type', key: 'type', d_align: 'center' },
                        { title: 'Amount', key: 'amount', d_align: 'center' },
                    ]}
                    content={previousPayments?.map((prev, i) => {
                        console.log({ prev })
                        return (
                            {
                                sl_no: i + 1,
                                date: prev.date,
                                details: prev.feepays.map(pay => <p>{`${monthNameGetter(pay.fee?.month)} (${pay.fee.type}) - ${pay.amount}`}</p>),
                                receipt_no: prev.receipt_no,
                                // type: prev.type,
                                amount: prev.amount,
                            }
                        )
                    })}
                />
            </Page.Body>

        </Page>
    )
}
