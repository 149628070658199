import React from 'react'
import './Card.scss'
import { monthNameGetter } from '../../utils'
import { useNavigate } from 'react-router-dom'

export default function Card({ data }) {

    const navigate = useNavigate()
    const [month, year] = monthNameGetter(data.month).split('-')
    // estimated_total
    // total_students
    // total_paid
    // paid_students
    return (
        <div id='Card'>
            {/* {!data.paid_students && <img className='edit_icon' width={30} src="/icons/editbr.svg" alt="" onClick={() => navigate('/edit_student/' + data._id)} />} */}
            <span className='month-wrap'>
                <span className='month'>
                    <img width={30} className='calendar-icon' src="/icons/calendar.svg" alt="" />
                    <p>{month}</p>
                    <p>{year}</p>
                </span>
            </span>
            <div className='content'>
                <p className='expct'>Estimated Total &nbsp; : &nbsp; <b>{data.estimated_total}</b></p>
                <div className='to_details'>
                    {/* <div className='paid_details'>
                        <p>Paid Students : {data.paid_students || 0}</p>
                        <p>Paid Amount : {data.total_paid || 0}</p>
                    </div>
                    <div className='to_pay_details'>
                        <p>Remaining : {data.total_students || 0 - data.paid_students || 0}</p>
                        <p>Balance : {data.estimated_total || 0 - data.total_paid || 0}</p>
                    </div> */}
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Paid</th>
                                <th>Unpaid</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {/* <td><img width={20} src="/icons/caution.svg" alt="" /></td> */}
                                <td className='title'>Students</td>
                                <td>::</td>
                                {/* <td>Paid</td> */}
                                {/* <td>:</td> */}
                                <td className='paid'>{data.paid_students || 0}</td>
                                {/* <td>Unpaid</td> */}
                                {/* <td>/</td> */}
                                <td>{(data.total_students || 0) - (data.paid_students || 0)}</td>
                            </tr>
                            <tr>
                                {/* <td><img width={20} src="/icons/caution.svg" alt="" /></td> */}
                                <td className='title'>Amount</td>
                                <td>::</td>
                                {/* <td>Paid</td> */}
                                {/* <td>:</td> */}
                                <td className='paid'>{data.total_paid || 0}</td>
                                {/* <td>Unpaid</td> */}
                                {/* <td>/</td> */}
                                <td>{(data.estimated_total || 0) - (data.total_paid || 0)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
