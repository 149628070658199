import React from 'react'
import './StaffCard.scss'
import { useNavigate } from 'react-router-dom'

export default function StaffCard({ data }) {
    const navigate = useNavigate()
    return (
        <div id='StaffCard'>
            <img className='student_pic' width={80} height={80} src="/images/student.jpg" alt="" />
            <img className='edit_icon' width={30} src="/icons/edit.svg" alt="" onClick={() => navigate('edit_staff/' + data._id)} />
            <p className='name'>{data.name}</p>
            <p className='cicno'>{data.cic_number}</p>
        </div>
    )
}
