import React from 'react'
import Page from '../_common/_components/Page/Page'
import Title from '../_common/_components/Title/Title'
import Flex from '../_common/_components/Flex/Flex'
import Input from '../_common/_components/Input/Input'
import Select from '../_common/_components/Select/Select'
import useForm from '../_common/_Hooks/useForm'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import { useNavigate, useParams } from 'react-router-dom'
import Button, { Buttons } from '../_common/_components/Button/Button'
import useFetch from '../_common/_Hooks/useFetch'

export default function AddStaff() {

    const navigate = useNavigate()
    const { id } = useParams()
    const { formPlug, inputs, validate } = useForm()

    // **********************************************************************************************************
    const { req: addStaff } = useFetch({
        endpoint: 'add_staff',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // **********************************************************************************************************

    async function saveHandler() {
        if (!await validate()) return
        addStaff(inputs, () => {
            navigate('/staffs')
        })
    }

    return (
        <Page>

            <Page.Header>
                <Title title={id ? 'Edit Staff' : 'Add Staff'} />
                <Breadcrumb content={[
                    { path: '/', name: 'Staffs' },
                    { path: '/add_staff', name: id ? 'Edit Staff' : 'Add Staff' }
                ]} />
            </Page.Header>

            <Page.Body>

                <Flex columns={4} gap={20}>
                    <Input css={1} inputSocket={formPlug} label='Name' field='name' required />
                    <Input css={1} inputSocket={formPlug} label='Date of Joining' field='date_of_joining' required type='date' />
                    <Input css={1} inputSocket={formPlug} label='Salary' field='salary' required type='number' />
                </Flex>

                <Buttons mt={60} gap={30}>
                    <Button css={2.1} content='Cancel' onClick={() => navigate(-1)} />
                    <Button css={2} content='Save' onClick={saveHandler} />
                </Buttons>
            </Page.Body>

        </Page>
    )
}
