import React from 'react'
import Page from '../_common/_components/Page/Page'
import Title from '../_common/_components/Title/Title'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import Flex from '../_common/_components/Flex/Flex'
import Input from '../_common/_components/Input/Input'
import Button, { Buttons } from '../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'
import useForm from '../_common/_Hooks/useForm'

export default function AddClass() {

    const navigate = useNavigate()
    const { formPlug } = useForm()

    async function saveHandler() {

    }

    return (
        <Page>

            <Page.Header>
                <Title title={'Add Class'} />
                <Breadcrumb content={[
                    { path: '/fees', name: 'Classes' },
                    { path: '/add_fees', name: 'Add Class' }
                ]} />
            </Page.Header>

            <Page.Body>

                <Flex columns={4} gap={20}>
                    <Input css={1} inputSocket={formPlug} label='Year' field='year' required />
                    <Input css={1} inputSocket={formPlug} label='Class Teacher' field='year' required />
                    <Input css={1} inputSocket={formPlug} label='Class Leader' field='year' required />
                </Flex>
            </Page.Body>

            <Buttons mt={60} gap={30}>
                <Button css={2.1} content='Cancel' onClick={() => navigate(-1)} />
                <Button css={2} content='Save' onClick={saveHandler} />
            </Buttons>

        </Page>
    )
}
