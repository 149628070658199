import './Modals.scss'
import useTranslate from "../_common/_Hooks/useTranslate"
import Button, { Buttons } from "../_common/_components/Button/Button"
import Input from "../_common/_components/Input/Input"
import TextArea from "../_common/_components/TextArea/TextArea"
import Grid from "../_common/_components/Grid/Grid"
import FileUpload from "../_common/_components/FileUpload/FileUpload"
import useForm from "../_common/_Hooks/useForm"
import Files from "../_common/_components/Files/Files"
import Flex from '../_common/_components/Flex/Flex'
import Select from '../_common/_components/Select/Select'
import useFetch from '../_common/_Hooks/useFetch'
import { monthNameGetter } from '../utils'


// **************************************************************************************************************************************
// export const Confirm_phoneNumber_overwrite = ({ M, data }) => {
//     return (
//         <div>
//             <h3>Phone number exist with the number you provided</h3>
//             <div dir="ltr">
//                 <p>customer_contact :: {data.customer_contact}</p>
//                 <p>customer_email :: {data.customer_email}</p>
//                 <p>customer_gender :: {data.customer_gender}</p>
//                 {/* <p>customer_name_ar :: {data.customer_name_ar}</p> */}
//                 <p>customer_name_en :: {data.customer_name_en}</p>
//                 <p>customer_nationality :: {data.customer_nationality}</p>
//             </div>

//             <h3> Do you wish to change the number or proceed to overwrite?</h3>
//             <Buttons>
//                 <Button content="Change Number" onClick={() => M.Handler('change_number')} />
//                 <Button content="Overwrite" onClick={() => M.Handler('overwrite')} />
//                 <Button content="Alternate Number" onClick={() => M.Handler('alternate_number')} />
//             </Buttons>
//         </div>
//     )
// }
// // **************************************************************************************************************************************
// export const Confirm_with_comment = ({ M, action, m_inputs }) => {
//     const { inputs, formPlug } = useForm(m_inputs)
//     return (
//         <div>
//             <TextArea
//                 css={2}
//                 placeholder='Comment Here'
//                 field='comment'
//                 label={`Are you sure to ${action.action_status} ?`}
//                 inputSocket={formPlug}
//                 required
//             />
//             <Buttons end mt={20}>
//                 <Button content='Cancel' css={1} onClick={() => M.Handler('cancel')} />
//                 <Button content='Submit' css={1.1} onClick={() => M.Handler('submit', { m_inputs: inputs })} />
//             </Buttons>
//         </div>
//     )
// }
// // **************************************************************************************************************************************
// export const Success = ({ M, msg }) => {
//     const { t } = useTranslate()
//     return (
//         <div>
//             <h3>{t('Success Message')}</h3>
//             <p>{msg}</p>
//         </div>
//     )
// }
// Success.autoHide = true
// Success.hideCloseBtn = true
// Success.css = 1
// // **************************************************************************************************************************************
// export const Confirm = ({ M, action }) => {
//     return (
//         <div>
//             <h3>{`Are you sure want to ${action} ?`}</h3>
//             <Buttons end mt={20}>
//                 <Button content='Cancel' css={1} onClick={() => M.Handler('cancel')} />
//                 <Button content='Confirm' css={1.1} onClick={() => M.Handler('confirm')} />
//             </Buttons>
//         </div>
//     )
// }
// // **************************************************************************************************************************************
// export const FileViewer = ({ value, canDownload, downloader }) => {
//     return (
//         <div>
//             <Files.Preview file={value} />
//             {canDownload && <Button css={4} content="Download" icon='/assets/icons/dowld.svg' iconWidth={15} onClick={() => downloader()} />}
//             <p className="name">{value.name}</p>
//         </div>
//     )
// }
// FileViewer.width = 500
// FileViewer.className = 'fileViewer'
// // **************************************************************************************************************************************
// export const AddEditVolunteer = ({ M, isEdit, m_inputs, fileUploader }) => {
//     const { formPlug, inputs, validate } = useForm(m_inputs, { fileUploader })
//     return (
//         <div>
//             <h3>{isEdit ? 'Edit Volunteer' : 'Add Volunteer'}</h3>
//             <Grid columns={2}>
//                 <Input css={1} inputSocket={formPlug} label='First Name' field={'first_name'} required />
//                 <Input css={1} inputSocket={formPlug} label='Last Name' field={'last_name'} required />
//                 <Input css={1} inputSocket={formPlug} label='Phone' field={'phone_number'} required type='number' validation={{ phone: { digits: "6-13" }, maxNumber: 13 }} />
//                 <Input css={1} inputSocket={formPlug} label='Email' field={'email'} required validation={{ email: true }} />
//             </Grid>
//             <FileUpload
//                 css={2}
//                 mt={20}
//                 inputSocket={formPlug}
//                 field='eid'
//                 label="Emirates ID"
//                 dragNdrop
//                 buttonLabel="Drag and Drop 'Emirates ID' or click here"
//                 maxFileSizeMB={50}
//                 accept={{ items: ["pdf"], message: 'Only pdf format accepted' }}
//                 showPreview
//                 icon={'/assets/icons/upload.svg'}
//             />
//             <FileUpload
//                 css={3}
//                 mt={20}
//                 inputSocket={formPlug}
//                 field='attachments'
//                 label="Supporting Documents"
//                 limited={3}
//                 maxFileSizeMB={50}
//                 accept={{ items: ["jpg", "jpeg", "png", "pdf", "doc", "docx"], message: 'Only jpg, jpeg, png, pdf, doc and docx formats accepted' }}
//                 showPreview
//                 icon={'/assets/icons/plus_black.svg'}
//                 multiple
//             />
//             <Buttons end mt={20}>
//                 <Button content='Cancel' css={1} onClick={() => { M.Handler('cancel') }} />
//                 <Button content='Submit' css={1.1} onClick={async () => { await validate() && M.Handler('submit', { m_inputs: inputs }) }} />
//             </Buttons>
//         </div>
//     )
// }
// **************************************************************************************************************************************
export const AccountEntry = ({ M, type, categories, submitApi, data, addCategory, getCategories }) => {
    const { formPlug, inputs, validate, setErrors } = useForm(data)
    const categoryForm = useForm()
    let isIncome = type === 'income'
    let isExpense = type === 'expense'
    async function submitHandler() {
        if (!await validate()) return
        submitApi(inputs, (res) => {
            if (res.code === 11000) {
                setErrors(res.errors)
            } else {
                M.Handler('submitted')
            }
        })
    }
    async function categoryFormSubmitHandler() {
        if (!await categoryForm.validate()) return
        // M.Handler('categoryForm', { m_inputs: categoryForm.inputs })
        addCategory({ ...categoryForm.inputs, type, _id: data?._id }, () => {
            getCategories()
            M.Back()
        })
    }
    // console.log({ categories })
    return (
        <div id='AccountEntry'>
            <M.Pages>
                <>
                    <h3>{isIncome ? 'Income' : 'Expense'}</h3>
                    <hr />
                    <Flex columns={2} gap={20}>
                        <Input css={1} inputSocket={formPlug} label='Date' field='date' required type='date' />
                        {isIncome &&
                            <>
                                <Select css={1} inputSocket={formPlug} label='Particular' field='particular' required
                                    content={{ op: categories.income, vl: '_id', lb: 'categoryname' }}
                                    labelIcon={'/icons/squareplus.svg'} onLabelIconClick={() => M.Next()} />
                                <Input css={1} inputSocket={formPlug} label='Paid By' field='creditedBy' required />
                                <Input css={1} inputSocket={formPlug} label='Receipt No' field='receipt_no' type='number' required />
                            </>
                        }
                        {isExpense &&
                            <>
                                <Select css={1} inputSocket={formPlug} label='Particular' field='particular' required
                                    content={{ op: categories.expense, vl: '_id', lb: 'categoryname' }}
                                    labelIcon={'/icons/squareplus.svg'} onLabelIconClick={() => M.Next()} />
                                <Input css={1} inputSocket={formPlug} label='Paid To' field='paid_to' required />
                                <Input css={1} inputSocket={formPlug} label='Voucher/Bill No' field='vchr_no' type='number' required />
                            </>
                        }
                        <Input css={1} inputSocket={formPlug} label='Amount' field='amount' type='number' required />
                    </Flex>

                    <Input css={1} mt={30} inputSocket={formPlug} label='Remarks' field='remarks' />

                    <Buttons mt={60} gap={30}>
                        <Button css={2.1} content='Cancel' onClick={() => M.Handler('cancel')} />
                        <Button css={2} content='Submit' onClick={submitHandler} />
                    </Buttons>
                </>
                <>
                    <h3>{isIncome ? 'Add Income Category' : 'Add Expense Category'}</h3>
                    <hr />

                    <Input css={1} inputSocket={categoryForm.formPlug} label='Category Name' field='categoryname' required />

                    <Buttons mt={60} gap={30}>
                        <Button css={2.1} content='Back' onClick={() => M.Back()} />
                        <Button css={2} content='Submit' onClick={categoryFormSubmitHandler} />
                    </Buttons>
                </>
            </M.Pages>
        </div>
    )
}
AccountEntry.hideCloseBtn = true
// **************************************************************************************************************************************
export const SalaryEntry = ({ M, staffs }) => {
    const { formPlug, inputs, validate, inputHandler, setInputs } = useForm()
    // **********************************************************************************************************
    const { req: getDueSalaries, res: dueSalaries_res } = useFetch({
        endpoint: 'due_salaries',
        method: 'get',
        type: 'params',
    })
    // **********************************************************************************************************
    const dueSalaries = dueSalaries_res?.data?.salaries
    async function submitHandler() {
        if (!await validate()) return
        M.Handler('submit', { m_inputs: { ...inputs, staff: inputs.staff._id } })
    }
    function staffChangeHandler(value, key) {
        getDueSalaries(value._id)
        inputHandler(value, key)
    }
    function monthChangeHandler(value, key) {
        setInputs(s => ({ ...s, amount: value.balance }))
        inputHandler(value, key)
    }
    return (
        <div id='SalaryEntry'>
            <h3>{'Salary Payment'}</h3>
            <hr />
            <Flex columns={2} gap={20}>
                <Select css={1} inputSocket={formPlug} label='Staff' field='staff' required
                    content={{ op: staffs, vl: '_id', lb: 'name' }} onChange={staffChangeHandler} />
                <Select css={1} inputSocket={formPlug} label='Month' field='month' required
                    content={{ op: dueSalaries?.filter(f => f.balance !== 0), vl: '_id', lb: (p) => monthNameGetter(p.month) }} onChange={monthChangeHandler} />
                <Input css={1} inputSocket={formPlug} label='Date' field='date' required type='date' />
                <Input css={1} inputSocket={formPlug} label='Voucher No' field='vchr_no' required />
                <Input css={1} inputSocket={formPlug} label='Amount' field='amount' required />
            </Flex>
            <Buttons mt={60} gap={30}>
                <Button css={2.1} content='Cancel' onClick={() => M.Handler('cancel')} />
                <Button css={2} content='Submit' onClick={submitHandler} />
            </Buttons>
        </div>
    )
}
SalaryEntry.hideCloseBtn = true
// **************************************************************************************************************************************
export const AddClass = ({ M }) => {
    const { formPlug, inputs, validate, inputHandler, setInputs } = useForm({ batch: { value: new Date().getFullYear() } })
    async function submitHandler() {
        if (!await validate()) return
        M.Handler('submit', { m_inputs: { ...inputs, batch: inputs.batch.value } })
    }
    const currYear = new Date().getFullYear()
    const years = [...Array(11)].map((item, i) => ({ value: ((6 - (i + 1)) * -1) + currYear }))
    console.log(years)
    return (
        <div id="AddClass">
            <h3>{'Add Class'}</h3>
            <Flex columns={2} gap={20}>
                <Input css={1} inputSocket={formPlug} label='Name' field='name' required />
                <Input css={1} inputSocket={formPlug} label='Code' field='code' required />
                <Select css={1} inputSocket={formPlug} label='Year of Admission' field='batch' required
                    content={{ op: years, vl: 'value', lb: 'value' }} />
                <Input css={1} inputSocket={formPlug} label='Order' field='order' required />
                <Input css={1} inputSocket={formPlug} label='Class Teacher' field='class_teacher' required />
            </Flex>
            <Buttons mt={60} gap={30}>
                <Button css={2.1} content='Cancel' onClick={() => M.Handler('cancel')} />
                <Button css={2} content='Submit' onClick={submitHandler} />
            </Buttons>
        </div>
    )
}
AddClass.hideCloseBtn = true
// **************************************************************************************************************************************
