import React from 'react'
import Page from '../_common/_components/Page/Page'
import Title from '../_common/_components/Title/Title'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import Button from '../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'
import useFetch from '../_common/_Hooks/useFetch'
import Table from '../_common/_components/Table/Table'
import List from '../_common/_components/List/List'
import Grid from '../_common/_components/Grid/Grid'
import StaffCard from '../components/StaffCard/StaffCard'
import Flex from '../_common/_components/Flex/Flex'

export default function Staffs() {

    const navigate = useNavigate()

    // **********************************************************************************************************
    const { res: staffs_res } = useFetch({
        endpoint: 'get_staffs',
        method: 'get',
        type: 'raw',
        selfCall: true,
    })
    // **********************************************************************************************************
    const staffs = staffs_res?.data
    // **********************************************************************************************************

    return (
        <Page>

            <Page.Header>
                <Title title={'Staffs'} />
                <Breadcrumb content={[
                    { path: '/', name: 'Home' },
                    { path: '/', name: 'Staffs' }
                ]} />
                <Button css={1} content='Add Staff' icon={'/icons/plus.svg'} onClick={() => navigate('add_staff')} />
            </Page.Header>

            <Page.Body>
                <Flex className='standard' columns={6} gap={10} _minWidth={200}>
                    {
                        staffs?.map(student => {
                            return (
                                <StaffCard data={student} />
                            )
                        })
                    }
                </Flex>
            </Page.Body>

        </Page>
    )
}