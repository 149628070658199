import React from 'react'
import Page from '../_common/_components/Page/Page'
import Title from '../_common/_components/Title/Title'
import Table from '../_common/_components/Table/Table'
import Button from '../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'
import Breadcrumb from '../_common/_components/Breadcrumb/Breadcrumb'
import useFetch from '../_common/_Hooks/useFetch'
import StudentCard from '../components/StudentCard/StudentCard'
import Flex from '../_common/_components/Flex/Flex'
import Grid from '../_common/_components/Grid/Grid'

export default function Home() {

    const navigate = useNavigate()

    // **********************************************************************************************************
    const { res: details_res } = useFetch({
        endpoint: 'get_students',
        method: 'post',
        type: 'raw',
        selfCall: true,
        toast: true
    })
    // **********************************************************************************************************
    const details = details_res?.data
    // **********************************************************************************************************

    return (
        <Page className='Home'>

            <Page.Header>
                <Title title={'Students'} />
                <Breadcrumb content={[
                    { path: '/', name: 'Home' },
                    { path: '/', name: 'Students' }
                ]} />
                <Button css={1} content='Add Student' icon={'/icons/plus.svg'} onClick={() => navigate('add_student')} />
            </Page.Header>

            <Page.Body>
                {/* <Table
                    css={1}
                    titles={[
                        { title: 'Sl No', key: 'sl_no', d_align: 'center', width: 40 },
                        { title: 'Name', key: 'name', h_align: 'left' },
                        { title: 'CIC No', key: 'cic_number', d_align: 'center' },
                        { title: 'Class', key: 'class', d_align: 'center' },
                        { title: 'Batch', key: 'batch', d_align: 'center' },
                        { title: 'Place', key: 'place', d_align: 'center' },
                    ]}
                    content={details?.map((student, i) => {
                        return (
                            {
                                sl_no: i + 1,
                                name: student.name,
                                cic_number: student.cic_number,
                                class: student.class.name,
                                batch: student.batch,
                                place: student.place,
                            }
                        )
                    })}
                    /> */}
                {/* <div className='standard'> */}
                {details?.length > 0
                    ? details?.map((class_details) => {
                        return (
                            <>
                                <Title css={1} mt={10} title={class_details.class.name} />
                                <Grid className='standard' columns={6} gap={10} _minWidth={200}>
                                    {
                                        class_details?.students.map(student => {
                                            return (
                                                <StudentCard data={student} />
                                            )
                                        })
                                    }
                                </Grid>
                            </>
                        )
                    })
                    : <div className='fees_unavailable'>
                        <img src="" alt="" />
                        <p>No Students were added</p>
                    </div>
                }
                {/* </div> */}
            </Page.Body>

        </Page>
    )
}
